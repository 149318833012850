<template>
  <div class="check-list-hd-category-checkbox">
    <div class="category-ratings rating-card-block">
      <div class="img-rating-box">
        <div class="rating-left">
          <b-link class="img-rating-box-first">
            <div class="default-widget-rating-number">
              <span
                class="rating"
                v-if="
                kiyohRatings && kiyohRatings.last_12_month_average_rating
              "
              >
              {{
                kiyohRatings.last_12_month_average_rating
                  .toFixed(1)
                  .replace(".", ",")
              }}
              </span>
              <span class="rating" v-else> 0,0 </span>
            </div>
          </b-link>
          <div class="star-bottom">
            <div class="rating-star-icon">
              <span class="rating-wrap">
                <div class="rating-star-wrap">
                  <span
                    class="stars-active"
                    :style="`width: ${
                      (kiyohRatings.last_12_month_average_rating.toFixed(
                        1
                      ) /
                        10) *
                      100
                    }%`"
                  >
                    <b-icon icon="star-fill"></b-icon>
                    <b-icon icon="star-fill"></b-icon>
                    <b-icon icon="star-fill"></b-icon>
                    <b-icon icon="star-fill"></b-icon>
                    <b-icon icon="star-fill"></b-icon>
                  </span>
                  <span class="stars-inactive">
                    <b-icon icon="star"></b-icon>
                    <b-icon icon="star"></b-icon>
                    <b-icon icon="star"></b-icon>
                    <b-icon icon="star"></b-icon>
                    <b-icon icon="star"></b-icon>
                  </span>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="review-count">
          <h4>
            <span>{{ reviewtext }}</span>
          </h4>

          <b-link v-if="kiyohRatings && kiyohRatings.number_reviews">
            {{ kiyohRatings.number_reviews }} {{ $t("product_review") }}</b-link
          >
          <b-link v-else>0 {{ $t("product_review") }}</b-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SidebarRatingComponent",
  props: {
    msg: String,
  },
  data() {
    return {
      reviewtext: "Kiyoh",
    };
  },
  computed: {
    kiyohRatings() {
      return this.$store.getters["home/getKiyohRatings"];
    },
  },
};
</script>
