var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"match-heights",rawName:"v-match-heights",value:({ el: ['.productInfo', '.per-unit'] }),expression:"{ el: ['.productInfo', '.per-unit'] }"}],staticClass:"product-card"},[_c('b-modal',{ref:"product-delete-wish",staticClass:"product-modal-inner",attrs:{"id":"product-delete-wish","content-class":"defaul-modal","hide-footer":"","centered":"","size":"lg"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(_vm._s(_vm.$t("remove_product_wishlist"))+" ")]},proxy:true}])},[_c('div',{staticClass:"modal-text d-block border-bottom"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("sure_remove_product_wishlist"))+" ")])]),_c('div',{staticClass:"modal-btn-box pt-20"},[_c('b-button',{staticClass:"btn-success",attrs:{"block":""},on:{"click":_vm.clickOk}},[_vm._v("Ok")]),_c('b-button',{staticClass:"btn-success btn-outline",attrs:{"block":""},on:{"click":_vm.clickCancel}},[_vm._v(_vm._s(_vm.$t("cancel")))])],1)]),(_vm.product != null)?_c('div',{staticClass:"card-body custom-card-body"},[_c('div',{staticClass:"custom-card-body-link"},[(_vm.isInWishlist)?_c('b-link',{staticClass:"wishlist-icon-card",class:{ danger: _vm.isInWishlist },on:{"click":_vm.showPopup}},[_c('b-icon',{staticClass:"active",attrs:{"icon":"heart-fill"}})],1):_c('b-link',{staticClass:"wishlist-icon-card",class:{ danger: _vm.isInWishlist },on:{"click":_vm.updateWish}},[_c('b-icon',{staticClass:"active",attrs:{"icon":"heart"}})],1),_c('div',{staticClass:"product-img"},[_c('router-link',{attrs:{"to":("/" + (_vm.product.url_key) + "/")},nativeOn:{"click":function($event){return _vm.$root.$emit('closeSearchModal')}}},[(
              _vm.product.cashback_promotion ||
              (_vm.product.labels && _vm.product.labels.length)
            )?_c('div',{staticClass:"product-tag"},[(_vm.product.cashback_promotion)?_c('div',{staticClass:"tag discount-txt-wrap"},[_c('span',{staticClass:"discount-txt"},[_vm._v("€"+_vm._s(_vm.product.cashback_promotion.amount.value + " cashback")+" ")])]):_vm._e(),(_vm.product.labels && _vm.product.labels.length)?_c('div',{staticClass:"product-tag-wrap"},_vm._l((_vm.product.labels),function(label,idx){return _c('div',{key:idx,staticClass:"product-tag-single"},[(label.category.type === 'image')?_c('div',{staticClass:"img-label"},[_c('div',{staticClass:"tag-label",class:label.category.position,style:("width:" + (label.category.size) + "px;\n                    height:" + (label.category.size) + "px;\n                    background-image: url('" +
                      label.category.image_url +
                      "');\n                    " + (label.category.css) + ";")})]):_vm._e(),(label.category.type === 'text')?_c('div',{staticClass:"text-label"},[_c('div',{staticClass:"tag-label",class:label.category.position,style:(label.category.css)},[_vm._v(" "+_vm._s(label.category.text)+" ")])]):_vm._e()])}),0):_vm._e()]):_vm._e(),_c('b-img',{attrs:{"alt":_vm.product.name,"src":_vm.product.thumbnail.url
                ? _vm.product.thumbnail.url
                : _vm.product.thumbnail.medium}})],1)],1),_c('p',{staticClass:"offer-tag",class:{
          'opacity-0':
            _vm.product.price_range.maximum_price.discount.percent_off == 0,
        }},[_c('b-link',{staticClass:"offer-link"},[_vm._v(" - "+_vm._s(_vm.product.price_range.maximum_price.discount.percent_off.toFixed(0))+"% ")])],1),_c('b-card-text',{staticClass:"productInfo"},[_c('router-link',{attrs:{"to":("/" + (_vm.product.url_key) + "/")},nativeOn:{"click":function($event){return _vm.$root.$emit('closeSearchModal')}}},[_c('span',{staticClass:"merk"},[_vm._v(_vm._s(_vm.brand))]),_c('br'),_vm._v(" "+_vm._s(_vm.product.titel60)+" ")])],1),_c('div',{staticClass:"txt-div"},[(!_vm.isHomePage)?_c('div',{staticClass:"shopping-cart-icon"},[_c('span',{staticClass:"right"},[_c('b-link',{attrs:{"href":"#"},on:{"click":_vm.addToCart}},[_c('font-awesome-icon',{staticStyle:{"color":"#fff","font-size":"18px"},attrs:{"icon":"shopping-cart"}})],1)],1)]):_vm._e(),(
              _vm.product.price_range.minimum_price.regular_price.value !=
              _vm.product.price_range.minimum_price.final_price.value
            )?_c('span',{staticClass:"adv-price"},[_vm._v(_vm._s(_vm.$t("Adv_price")))]):_vm._e(),_c('br'),_c('p',{staticClass:"per-unit"},[(
              _vm.product.price_range.minimum_price.regular_price.value !=
              _vm.product.price_range.minimum_price.final_price.value
            )?_c('span',{staticClass:"strike-txt font-18"},[_c('span',{staticClass:"font-12"},[_vm._v(" "+_vm._s(_vm.formatCurrency( _vm.product.price_range.minimum_price.regular_price.value )))])]):_vm._e(),_c('span',{staticClass:"strike-txt"},[_c('strong',[_vm._v(" "+_vm._s(_vm.formatCurrency( _vm.product.price_range.minimum_price.final_price.value )))]),(_vm.product.unit)?_c('span',{staticClass:"font-18"},[_vm._v(" "+_vm._s(_vm.product.unit)+" ")]):_vm._e()])]),(_vm.product.stock_status !== 'IN_STOCK')?_c('DeliveryTime',{attrs:{"data":_vm.product.DeliveryTime}}):_c('p',{staticClass:"stuck-info stuck-size bold pb-15"},[(
              _vm.product.stock_qty > _vm.product.stock_display_limit ||
              _vm.product.stock_display_limit == null
            )?_c('span',[_vm._v(" "+_vm._s(_vm.$t("in_stock")))]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("x_left_in_stock", { qty: _vm.product.stock_qty })))])])],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }